import { find } from 'lodash-es';
import { derived, type Readable, writable, get } from 'svelte/store';
import { defaultLocale } from '$lib/i18n'; // TODO: storybook에서 meta가 읽히면서 i18n 작업들이 문제되어 동작하지 않는 문제로 임시 처방
import { _ } from 'svelte-i18n';
import { Aireview2Service, AlexService, ConhubService, DareDevilService, MtmService } from '$lib/api';

/**
 * currencyUnitItems example
 * [{ code: 'KRW', label: 'KRW', value: 'KRW', defaultFractionDigits: 0, englishName: "South Korean Won", name: "대한민국 원", symbol: "₩" },]
 */

type MetaProductType = 'CLM' | 'ELM' | 'AI_REVIEW' | 'SUPERADMIN';
interface currencyUnitItem {
    code: string;
    label: string;
    value: string;
    defaultFractionDigits: number;
    englishName: string;
    name: string;
    symbol: string;
}

interface MetaStore {
    // elmMeta
    elmMeta: Record<string, any>;
    consultingTypeItems: any[];
    finishStatusItems: any[];
    displayStepCodeItems: any[];
    courtNameItems: any[];
    courtDateTypesItems: any[];
    lawsuitEventTypesItems: any[];
    lawsuitCategoryItems: any[];
    litigationCategoryItems: any[];
    instanceTypeItems: any[];
    attorneyTypeItems: any[];
    ourPositionTypeItems: any[];
    completionTypeItems: any[];
    currencyItems: any[];
    expenseItems: any[];
    paymentMethodItems: any[];
    countryItems: any[];

    // clmMeta
    contractMeta: Record<string, any>;
    currencyUnitItems: currencyUnitItem[];
    templateItems: any[];
    languageItems: any[];
    contractTypeItems: any[];
    workspaceContractTypeItems: any[];
    workflowPresetTypes: ConhubWorkflowPresetDto['workflowTypes'];
    aiMeta: Record<string, any>;
    myRequestRolesItems: any[];
    codeItems: any[];
    standardFormStatusesItems: any[];
    counterpartyTypeItems: any[];
    getCodeLabel: (code: string) => string;
    getCounterpartyTypeLabel: (counterpartyType: string) => string;

    // repositoryMeta
    repositoryMeta: Record<string, any>;

    // 전역 설정
    globalConfigs: MtmConfigurationDto[];
}

const contractMeta = writable({
    currencies: [],
    templates: [],
    languages: [],
    myRequestRoles: [],
    workflowPresetTypes: [],
    workspaceContractTypeKeys: [],
} as Record<string, Record<string, any>[]>);

const repositoryMeta = writable({
    repositoryCodes: [],
} as Record<string, Record<string, any>[]>);

const elmMeta = writable<{
    consultingCodes?: Record<string, Record<string, any>[]>;
    lawsuitCodes?: Record<string, Record<string, any>[]>;
    litigationCodes?: Record<string, Record<string, any>[]>;
    litigationExpenseCodes?: Record<string, Record<string, any>[]>;
    lawfirmCodes?: Record<string, Record<string, any>[]>;
}>({});

const langCodes = () => [
    { code: 'KOREAN', label: get(_)('stores.meta.languageCodeKeyKorean'), value: 'KOREAN', abbreviation: 'KO' },
    { code: 'ENGLISH', label: get(_)('stores.meta.languageCodeKeyEnglish'), value: 'ENGLISH', abbreviation: 'EN' },
    { code: 'ETC', label: get(_)('stores.meta.languageCodeKeyEtc'), value: 'ETC', abbreviation: 'ETC' },
];
const aiMeta = writable({} as Record<string, any>);

const standardFormStatuses = () => [
    { label: get(_)('stores.meta.standardFormStatusActive'), value: 'ACTIVE' },
    { label: get(_)('stores.meta.standardFormStatusInactive'), value: 'INACTIVE' },
];
const getCounterpartyTypes = () => [
    { value: { counterpartyType: 'CORPORATION' }, label: get(_)('stores.meta.counterpartyTypesCorporationTotal') },
    // 계열/비계열 분리 타입들은 대기업향 Enterprise 모델에서 사용될 수 있음
    // { value: { counterpartyType: 'CORPORATION_AFFILIATE' }, label: get(_)('stores.meta.counterpartyTypesCorporation') },
    // { value: { counterpartyType: 'CORPORATION_NON_AFFILIATE' }, label: get(_)('stores.meta.counterpartyTypesCorporation2') },
    { value: { counterpartyType: 'SOLE_PROPRIETORSHIP' }, label: get(_)('stores.meta.counterpartyTypesSoleProprietorship') },
    { value: { counterpartyType: 'INDIVIDUAL' }, label: get(_)('stores.meta.counterpartyTypesIndividual') },
];

// productType 과 path를 매핑
const productTypeToPath = {
    WORKSPACE: '/[workspaceName]/admin/workspace-info/',
    CLM: '/[workspaceName]/clm/home/',
    ELM: '/[workspaceName]/elm/home/',
    AI_REVIEW: '/[workspaceName]/ai-review/home/',
    LEGAL_AGENT: '/[workspaceName]/business-agent/',
};

// 전역 설정 스토어
const globalConfigStore = writable<MtmConfigurationDto[]>([]);

// 전역 설정 로드 함수
export async function loadGlobalConfigurations() {
    try {
        const response = await MtmService.listConfigurations();
        if (response.data.data) {
            globalConfigStore.set(response.data.data);
        }
    } catch (error) {
        globalConfigStore.set([]);
        throw error;
    }
}

// 전역 설정값 조회 헬퍼 함수
export function getGlobalConfigValue(configKey: string) {
    const configs = get(globalConfigStore);
    const config = configs.find(c => c.configKey === configKey);
    if (!config?.configValue) return null;

    switch (config.dataType) {
        case 'BOOLEAN':
            return config.configValue === 'true';
        case 'LONG':
            return parseInt(config.configValue);
        case 'COMMA_SEPARATED_LONG':
            return config.configValue.split(',').map(v => parseInt(v));
        case 'COMMA_SEPARATED_STRING':
            return config.configValue.split(',');
        default:
            return config.configValue;
    }
}

export const meta: Readable<MetaStore> = derived([globalConfigStore, contractMeta, repositoryMeta, elmMeta, aiMeta], ([$gc, $cm, $rm, $em, $ai], set) => {
    const $codes = langCodes();
    const counterpartyTypes = getCounterpartyTypes();
    set({
        elmMeta: $em,
        // 자문 분류
        consultingTypeItems: $em.consultingCodes?.consultingCategory.map(consultingType => ({ ...consultingType, value: consultingType.code, label: consultingType.description })) || [],
        // 승인,반려 구분
        finishStatusItems: $em.consultingCodes?.finishStatus.map(status => ({ ...status, value: status.code, label: status.description })) || [],
        // 자문 step
        displayStepCodeItems:
            $em.consultingCodes?.displayStepCode.filter(stepCode => stepCode.code !== 'NOT_ALLOWED').map(stepCode => ({ ...stepCode, value: stepCode.code, label: stepCode.description })) || [],
        // 법원명
        courtNameItems: $em.lawsuitCodes?.courtNames.map(value => ({ value, label: value })) || [],
        // 변론기일, 감정기일, 추정기일
        courtDateTypesItems: $em.lawsuitCodes?.courtDateTypes.map(courtDateType => ({ ...courtDateType, value: courtDateType.code, label: courtDateType.description })) || [],
        // 기일, 명령, 제출서류, 송달 등
        lawsuitEventTypesItems: $em.lawsuitCodes?.lawsuitEventTypes.map(lawsuitEventType => ({ ...lawsuitEventType, value: lawsuitEventType.code, label: lawsuitEventType.description })) || [],
        // 가단, 가합 등
        lawsuitCategoryItems: $em.lawsuitCodes?.categoryNames.map(value => ({ value, label: value })) || [],
        // 사건분류
        litigationCategoryItems:
            $em.litigationCodes?.litigationCategory.map(litigationCategory => ({ ...litigationCategory, value: litigationCategory.code, label: litigationCategory.description })) ?? [],
        // 심급
        instanceTypeItems: $em.litigationCodes?.instanceType.map(instanceType => ({ ...instanceType, value: instanceType.code, label: instanceType.description })) ?? [],
        // 진행주체
        attorneyTypeItems: $em.litigationCodes?.attorneyType.map(attorneyType => ({ ...attorneyType, value: attorneyType.code, label: attorneyType.description })) ?? [],
        // 자사지위
        ourPositionTypeItems: $em.litigationCodes?.ourPositionType.map(ourPositionType => ({ ...ourPositionType, value: ourPositionType.code, label: ourPositionType.description })) ?? [],
        // 종결이유
        completionTypeItems: $em.litigationCodes?.completionType.map(completionType => ({ ...completionType, value: completionType.code, label: completionType.description })) ?? [],
        // currency 옵션 목록
        currencyItems: $em.litigationExpenseCodes?.currency.map(currency => ({ ...currency, value: currency.code, label: currency.description })) ?? [],
        // 비용 항목
        expenseItems: $em.litigationExpenseCodes?.expenseItem.map(expenseItem => ({ ...expenseItem, value: expenseItem.code, label: expenseItem.description })) ?? [],
        // 비용 방식, 지불 방식
        paymentMethodItems: $em.litigationExpenseCodes?.paymentMethod.map(paymentMethod => ({ ...paymentMethod, value: paymentMethod.code, label: paymentMethod.description })) ?? [],
        // lawfirm 국가 코드
        countryItems: $em.lawfirmCodes?.country.map(country => ({ ...country, value: country.code, label: country.description })) ?? [],
        aiMeta: $ai,
        contractMeta: $cm,
        currencyUnitItems: ($cm.currencies?.slice(0, 5).map(currency => ({ ...currency, label: currency.code, value: currency.code })) as currencyUnitItem[]) || [],
        templateItems: $cm.templates?.map(template => ({ ...template, label: template.description, value: template.code })) || [],
        languageItems: $cm.languages?.map(lang => ({ ...lang, label: lang.description, value: lang.code })) || [],
        contractTypeItems: $cm.contractTypes?.map(type => ({ ...type, label: type.displayName, value: type.type })) || [],
        workspaceContractTypeItems:
            $cm.contractTypes
                ?.filter(({ language, type }) => {
                    return $cm.workspaceContractTypeKeys?.find(key => key.contractType === type && key.language === language);
                })
                .map(typeItem => ({ ...typeItem, label: typeItem.displayName, value: typeItem.type })) || [],
        myRequestRolesItems: $cm.myRequestRoles?.map(role => ({ ...role, label: role.description, value: role.code })) || [],
        workflowPresetTypes: $cm.workflowPresetTypes || [],

        codeItems: $codes,
        counterpartyTypeItems: counterpartyTypes,
        standardFormStatusesItems: standardFormStatuses(),
        getCodeLabel: (code: string) => find($codes, ['code', code])?.label || code,
        getCounterpartyTypeLabel: (counterpartyType: string) => counterpartyTypes.find(type => type.value.counterpartyType === counterpartyType)?.label || '-',

        repositoryMeta: $rm,

        // 전역 설정 추가
        globalConfigsMeta: $gc,
        getGlobalConfigValue: getGlobalConfigValue,
    });
});

let prevProductMetaKey = '';
export const fetchRefreshMetaInfo = async (workspaceName, productType: MetaProductType) => {
    const tempProductKey = `${workspaceName}_${productType}`;
    if (!workspaceName || prevProductMetaKey === tempProductKey) return;

    if (productType === 'ELM') {
        const [consultingCodesRes, lawsuitCodeRes, litigationCodesRes, litigationExpenseCodesRes, lawfirmCodesRes] = await fetchELMMeta();
        elmMeta.set({
            consultingCodes: consultingCodesRes.data.data,
            lawsuitCodes: lawsuitCodeRes.data.data,
            litigationCodes: litigationCodesRes.data.data,
            litigationExpenseCodes: litigationExpenseCodesRes.data.data,
            lawfirmCodes: lawfirmCodesRes.data.data,
        });
    } else if (productType === 'CLM') {
        const [[cmRes, workspaceContractTypeKeysRes, repositoryCodesRes, consultingCodesRes, workflowPresetRes], [codesResponse, contractTypesResponse]] = await Promise.all([
            fetchContractMeta(),
            fetchAiMeta(),
        ]);
        contractMeta.set({
            ...cmRes.data.data,
            workspaceContractTypeKeys: workspaceContractTypeKeysRes.data.data,
            workflowPresetTypes: workflowPresetRes.data.data?.workflowTypes || [],
        });
        repositoryMeta.set({
            ...repositoryCodesRes.data,
            clauseTypes: repositoryCodesRes.data.clauseTypes?.map(item => ({ ...item, label: item.description, value: item.type })).sort((a, b) => a.label.localeCompare(b.label)) || [],
            contractTypes: repositoryCodesRes.data.contractTypes?.map(item => ({ ...item, label: item.description, value: item.type })) || [],
            counterpartyTypes: repositoryCodesRes.data.counterpartyTypes?.map(item => ({ ...item, label: item.description, value: item.code })) || [],
            currencies: repositoryCodesRes.data.currencies?.map(item => ({ ...item, label: item.description, value: item.code })) || [],
            languages: repositoryCodesRes.data.languages?.map(item => ({ ...item, label: item.description, value: item.code })) || [],
        });
        // ELM의 관련 자문 모달을 CLM에서 띄울 수 있기 때문에 필요
        elmMeta.set({
            consultingCodes: consultingCodesRes.data.data,
        });
        aiMeta.set({
            aiCodesItems: codesResponse.data.data,
            aiContractTypesItems: contractTypesResponse.data.data,
        });
    } else if (productType === 'AI_REVIEW') {
        const [codesResponse, contractTypesResponse, clmRes] = await fetchAiMeta();
        contractMeta.set({
            ...clmRes.data.data,
        });
        aiMeta.set({
            aiCodesItems: codesResponse.data.data,
            aiContractTypesItems: contractTypesResponse.data.data,
        });
    } else if (productType === 'SUPERADMIN') {
        const cmRes = await ConhubService.getContractMetaV2().then(res => res.data.data);
        contractMeta.set({
            ...cmRes,
        });
    }
    prevProductMetaKey = tempProductKey;
};

export const refreshCurrentMetaInfo = async () => {
    const [workspaceName, productType] = prevProductMetaKey.split('_');
    if (workspaceName && productType) {
        prevProductMetaKey = '';
        await fetchRefreshMetaInfo(workspaceName, productType as MetaProductType);
    }
};

async function fetchELMMeta() {
    return Promise.all([
        DareDevilService.getConsultingCodes(),
        DareDevilService.getLawsuitCodes(),
        DareDevilService.getLitigationCodes(),
        DareDevilService.getLitigationExpenseCodes(),
        DareDevilService.getLawFirmCodes(),
    ]);
}
async function fetchContractMeta() {
    return Promise.all([
        ConhubService.getContractMetaV21(),
        ConhubService.getWorkspaceContractTypes(),
        AlexService.getRepositoryCodesV2(),
        DareDevilService.getConsultingCodes(),
        ConhubService.getWorkflowPreset(),
    ]);
}

async function fetchAiMeta() {
    return Promise.all([Aireview2Service.codesV2(), Aireview2Service.contractTypesV2(), ConhubService.getContractMetaV21()]);
}

// 각 프로덕트의 홈 경로를 구함. 웍스페이스에 진입하지 않은 상위 페이지 또는 컴포넌트에서는 workspaceName 을 꼭 전달해줘야 한다.
export const getHomePathByProductType = (productType: string, workspaceName?: string) =>
    (productTypeToPath[productType] || '/[workspaceName]/').replace('/[workspaceName]', workspaceName ? `/${workspaceName}` : '');

// Actions
export const resetMeta = () => {
    prevProductMetaKey = '';
    contractMeta.set({});
    elmMeta.set({});
    aiMeta.set({});
};
